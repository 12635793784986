@import '../../includes/settings.scss';

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1 1 100%;
    padding: 0 20%;

    .title-container, .content-container {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .title, .content {
            flex: 1 1 auto;
            justify-content: center;
            text-align: center;
            max-width: 750px;
        }
    
        .title {
            padding: 0 0 $spacingParagraph 0;
            text-transform: uppercase;
        }
    } 
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .header {
        padding: 0 $mobileGutter;
    }
}