@import '../../includes/settings.scss';

.PPAPost {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;

    .postBody {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1 1 90%;
        margin: 0 auto;
        padding: $spacingParagraph 0;
        min-height: 800px;
        max-width: $maxWidth;
        background: #E5E5E5;
        border-radius: 10px;

        .title {
            position: absolute;
            left: -15px;
            top: 70px;
            background-color: $navbar-background;
            color: $text-light;
            padding: 1rem 2rem;
            text-transform: uppercase;
            border-radius: 0px 10px 10px 0px;
            z-index: 10;
        }

        .img-container {
            display: flex;
            flex-direction: row;
            flex: 1 1 100%;
            justify-content: center;

            .img {
                flex: 0 1 auto;
                justify-content: center;

                img, video {
                    max-height: 520px;
                    max-width: 782px;
                    align-self: flex-start;
                }

                video {
                    height: 520px;
                    width: 782px;
                    outline: none;
                }
            }

            .date {
                position: absolute;
                bottom: 50px;
                right: 50px;
            }
        }

        .content-container {
            display: flex;
            flex-direction: row;
            flex: 1 1 100%;
            justify-content: center;
            padding: $spacingParagraph 0;

            .content {
                max-width: $maxContentWidth;
                
                p {
                    padding: 0 0 1rem 0;
                }

                p:last-child {
                    padding: 0 0 0 0;
                }
            }
        }

        .readMore {
            justify-content: flex-end;
            display: flex;
            flex-direction: row;
            flex: 1 1 100%;
            max-width: $maxContentWidth;
            padding: 0;
        }

        .sharing {
            display: flex;
            flex-direction: row;
            flex: 1 1 100%;
            padding: 0 0 0 4rem;

            .shareTitle {
                display: inline-flex;
                flex: 0 1 auto;
                text-transform: uppercase;
                font-size: 18px;
                line-height: 20px;
                padding: 0 3rem 0 0;
            }

            .sharing-options {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex: 0 1 auto;
                list-style: none;
                
                .sharing-item {
                    flex: 0 1 auto;
                    padding: 0 3rem 0 0;
                    min-height: 1.75rem;
                    min-height: 1.75rem;
                    align-items: center;
                    justify-content: center;

                    .sharing-link {
                        display: flex;
                        flex-direction: row;
                        flex: 0 1 auto;
                        align-items: center;
                        justify-content: center;

                        .social-icon {
                            height: 1.5rem;
                            width: 1.5rem;
                            transition: all .5s ease;
                        }
    
                        &:hover {
                            .social-icon {
                                height: 1.75rem;
                                width: 1.75rem;
                            }
                        }
                    }

                    
                }

                
            }
        }
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .PPAPost {
        padding: 0 $mobileGutter;

        .postBody {
            min-height: unset;
            
            .title {
                top: 0px;
            }
    
            .img-container {
                .date {
                    position: absolute;
                    bottom: -35px;
                    right: 1rem;
                }
            }

            .img-container {
                .img {
                    flex: 1 1 auto;
    
                    img {
                        max-height: unset;
                        max-width: unset;
                    }

                    video {
                        width: 100%;
                        height: auto;
                    }
                }
    
                .date {
                    position: relative;
                    bottom: unset;
                    right: unset;
                    display: flex;
                    flex: 1 1 100%;
                    justify-content: flex-end;
                    padding: $spacingParagraph $mobileGutter 0 $mobileGutter;
                    max-width: 830px;
                }
            }

            .content {
                padding: $spacingParagraph 1rem;
                text-align: center;
            }

            .content-container {
                .content {
                    padding: $spacingParagraph 1rem;
                    text-align: center;
                }
            }
    
            .readMore {
                padding: 0 1rem $spacingParagraph 1rem;;
            }
    
            .sharing {
                padding: 0 0 0 1rem;
                max-width: 830px;
    
                .shareTitle {
                    flex: 1 1 100%;
                    padding: 0 0 1rem 0;
                }
    
                .sharing-options {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex: 0 1 auto;
                    list-style: none;
                    
                    .sharing-item {
                        flex: 0 1 auto;
                        padding: 0 3rem 0 0;
                        min-height: 1.75rem;
                        min-height: 1.75rem;
                        align-items: center;
                        justify-content: center; 
                    }
    
                    
                }
            }
        }
    }
}