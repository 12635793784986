@import '../../includes/settings.scss';

.navbar-header {
    display: none;
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .navbar-header {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        background-color: $navbar-background;
        justify-content: space-between;
        padding: 0 1rem;

        .logo {
            flex: 0 1 auto;

            img {
                max-width: 62px;
                max-height: 46px;
            }
        }

        .hamburger-button {
            display: flex;
            flex: 0 1 auto;
            
            svg {
                height: 29px;
                width: 29px;
                color: $text-light;
            }
        }

        .nav-item {
            display: flex;
            flex: 0 1 auto;
            color: $text-light;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}