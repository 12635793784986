@import '../../includes/settings.scss';

.contactContainer {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: $maxWidth;
    margin: auto;

    .col {
        flex: 1 1 0;
        justify-content: center;
        align-content: flex-start;
        
        .title-container {
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0 0 $spacingParagraph 0;

            .title {
                flex: 0 1 auto;
                text-transform: uppercase;
            }
        }

        .content-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex: 1 1 100%;

            .content {
                max-width: 425px;
                justify-content: center;

                p, img {
                    margin: 0 0 $spacingParagraph 0;
                }
    
                p {
                    flex: 1 1 100%;
                }
    
                img {
                    max-height: 164px;
                    max-width: 164px;
                    border-radius: 50%;
                }
            }
        }

        .form-container {
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .contact-form {
                justify-content: center;
                max-width: 425px;

                .field-group {
                    label {
                        display: none;
                    }

                    .form-input-container {
                        input, textarea {
                            background-color: $navbar-background;
                            border-radius: 10px;
                            padding: 20px 40px;
                            color: $text-light;
                        }

                        ::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
                            color: #858585;
                        }
                    }
                }

                .btn-primary {
                    flex: 0 1 auto;
                }
            }
        }

        .socials {
            display: flex;
            flex-direction: row;
            flex: 1 1 100%;
            justify-content: center;

            .title {
                padding: 0 0 $spacingParagraph 0;
            }

            .social-list {
                display: flex;
                flex-direction: row;
                flex: 1 1 100%;
                justify-content: center;
                list-style: none;

                .social-item {
                    padding: 0 0 0 0;

                    .social-link {
                        img {
                            width: 29px;
                            height: 24px;
                        }
                    }
                }

                .social-item.middle {
                    padding: 0 55px;
                }
            }
        }
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .contactContainer {
        .col {
            .title {
                padding: $spacingParagraph 0;
            }

            .content-container {
                padding: 0 $mobileGutter;
            }
        }

        .socials {
            text-align: center;
        }
    }
}