@import '../../includes/settings.scss';

.navbar {
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;
    background-color: $navbar-background;
    transition: width 200ms ease;
    position: fixed;
    z-index: 1000;

    .navbar-nav {
        position: relative;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 100vh;
        width: $navbar-width;

        .header, .logo, .hamburger-button, .nav-item {
            display: flex;
            flex-direction: row;
            flex: 0 1 auto;
        }

        .header {
            padding: 0 0 200px 0;

            .hamburger-button {
                display: none;
            }
        }

        .nav-item {
            padding: 0 1rem 1.5rem 1rem;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            font-family: 'Karla-Regular';

            .nav-link {
                .link-text {
                    color: $text-light;

                    &::after {
                        content: '';
                        width: 0;
                        height: 2px;
                        margin-top: .25rem;
                        display: block;
                        background-color: $text-light;
                        transition: all .5s ease;
                    }
                }
            }
            
            .nav-link.active {
                .link-text {
                    &::after {
                        width: 100%;
                    }
                }
            }
            
            &:hover {
                .nav-link {
                    .link-text {
    
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .nav-item.dropdown {
            flex-wrap: wrap;

            .dropdown {
                flex: 1 1 100%;
                display: flex;
                flex-direction: row;
                padding: 0 0 0 1rem;
                height: 0px;
                overflow: hidden;
                transition: all .5s ease;

                .dropdown-list {
                    list-style: none;

                    .dropdown-item {
                        padding: 0 0 1.5rem 0;

                        .nav-link {
                            font-size: 12px;
                            line-height: 14px;
                            text-transform: capitalize;
    
                            .link-text {
                                color: #858585;
                                transition: all .5s ease;

                                &::after {
                                    content: '';
                                    width: unset;
                                    height: unset;
                                    margin-top: unset;
                                    display: none;
                                }

                                &:hover {
                                    color: $text-light;
                                }
                            }

                            
                        }

                        .nav-link.active {
                            .link-text {
                                color: $text-light;
                            }
                        }
                    }

                    .dropdown-item:last-child {
                        padding: 0 0 0 0;
                    }
                }
            }

            &:hover {
                .dropdown {
                    height: auto;
                    padding: 1.5rem 0 1.5rem 1rem;
                }
            }
        }

        .nav-item.dropdown.expand {
            .dropdown {
                height: auto;
                padding: 1.5rem 0 1.5rem 1rem;
            }
        }

        .socials {
            margin: 180px 0 0 0;

            .nav-item {
                padding: 0 2rem 1.5rem 2rem;

                .nav-link {
                    .social-icon {
                        height: 1.5rem;
                        width: 1.5rem;
                        transition: all .5s ease;
                    }
                }

                &:hover {
                    .nav-link {
                        .social-icon {
                            height: 1.75rem;
                            width: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}

/* Small screens */
@media only screen and (min-width: 1921px) {
    .navbar {
        left: calc((100% - 1920px) / 2);
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .navbar {
        width: 0;
        transition: width 200ms ease;

        .navbar-nav {
            flex-direction: column;
            width: 0;
            display: none;
    
            .header, .logo, .hamburger-button, .nav-item {
                display: flex;
                flex-direction: row;
                flex: 0 1 auto;
            }
    
            .header {
                justify-content: space-between;
                padding: 0 1rem 2rem 1rem;
                width: calc(100% - 2rem);

                .logo {
                    flex: 0 1 auto;
                }
    
                .hamburger-button {
                    display: flex;
                    flex: 0 1 auto;
                    
                    .nav-link {
                        font-size: 3rem;
                        color: $text-light;
                    }
                }
            }
    
            .nav-item {
                padding: 0 1rem 1.5rem 1rem;
            }
    
            .socials {
                margin: 4rem 0 0 0;
                flex: 0 1 auto;
                width: 100%;
    
                .nav-item {
                    flex: 1 1 auto;
                    width: calc(100% - 4rem);
                    padding: 0 2rem 1.5rem 2rem;
    
                    .nav-link {
                        .social-icon {
                            height: 1.5rem;
                            width: 1.5rem;
                            transition: all .5s ease;
                        }
                    }
    
                    &:hover {
                        .nav-link {
                            .social-icon {
                                height: 1.75rem;
                                width: 1.75rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar.open {
        width: 100%;

        .navbar-nav {
            width: 100%;
            display: flex;
        }
    }
}