@import '../../includes/settings.scss';

.ParagraphSpacer {
    margin-bottom: $spacingParagraph;
}

.BlockSpacer {
    margin-bottom: $spacingBlock;
}

.PriceSpacer {
    display: inline-flex;
    width: 50px;
}