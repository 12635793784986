.PPAPost-FullPost {
    .PPAPost {
        .postBody {
            .content-container {
                .content {
                    display: block;
                    width: 100%;
                }
            }

        }
    }
}