// Colors
$primary: black;
$secondary: white;
$text-light: white;
$text-dark: black;

// Breakpoints
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;

// Navbar
$navbar-width: 125px;
$navbar-background: black;

// Spacings
$spacingParagraph: 2rem;
$spacingBlock: 2.5rem;
$mobileGutter: 1rem;

// Max Width
$maxContentWidth: 830px;
$maxWidth: 1250px;
