@import './includes/settings.scss';

// Fonts
@font-face {
    font-family: 'Karla-Regular';
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/Karla/static/Karla-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Karla-Light';
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/Karla/static/Karla-Light.ttf') format('truetype');
}


:root {
    font-size: 14px;
    font-family: 'Karla-Regular';
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}
  
body {
    color: $text-dark;
    background-color: white;
    margin: 0;
    padding: 0;

    div {
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    li, ul, ol, p, h1, h2, h3, h4, h5, h6 {
        padding: unset;
        margin: unset;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    main {
        flex: 1 1 100%;
        margin-left: $navbar-width;
    }

    .container {
        display: flex;
        flex: 1 1 100%;
    }

    hr {
        display: flex;
        flex: 1 1 100%;
        border-color: rgba(0,0,0,0.1);
    }

    h1 {
        font-size: 48px;
        line-height: 56px;
        font-weight: 400;
        font-style: normal;
        font-family: 'Karla-Regular';
    }

    h2 {
        font-size: 36px;
        line-height: 42px;
        font-weight: 400;
        font-style: normal;
        font-family: 'Karla-Regular';
    }

    h3 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
        font-style: normal;
        font-family: 'Karla-Light';
    }

    h4 {
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        font-style: normal;
        font-family: 'Karla-Light';
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}
  
body::-webkit-scrollbar-track {
    background: #1e1e24;
}
  
body::-webkit-scrollbar-thumb {
    background: $primary;
}

// Theme Options
.dark {
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
}

.light {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #ffffff;
    --bg-secondary: #e4e4e4;
}

.solar {
    --text-primary: #576e75;
    --text-secondary: #35535c;
    --bg-primary: #fdf6e3;
    --bg-secondary: #f5e5b8;
}

.theme-icon {
    display: none;
}

.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}

.text-muted {
    color: #6c757d!important;
}

// Button Styles
.btn-primary {
    background-color: $primary;
    border: 1px solid $text-dark;
    border-radius: 10px;
    color: $text-light;
    padding: 12px 40px;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;

    &:hover { 
        background-color: transparent;
        border-color: $text-dark;
        color: $text-dark;
    }

    &:active { 
        text-decoration: underline;
    }

    &:focus { 
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($primary,.1);
    } 

    &:disabled {
        filter: grayscale(100%) opacity(0.4);
        cursor: not-allowed;
    }
}

.btn-link {
    background-color: transparent;
    border: unset;
    color: $text-dark;
    font-weight: 700;
    text-decoration: none;

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        margin-top: .25rem;
        display: block;
        background-color: $text-dark;
        transition: all .5s ease;
    }

    &:hover { 

        &::after {
            width: 0;
        }
    }

    &:disabled {
        filter: grayscale(100%) opacity(0.4);
        cursor: not-allowed;
    }
    
}

.fixedBackground {
    background-image: url('../assets/web_assets/all_pages_assets/fixed-background-img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
}

/* Large screens */
@media only screen and (min-width: 1921px) {
    body {
        main {
            flex: 1 1 100%;
            margin-left: unset;
        }
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    body {
        main {
            flex: 1 1 100%;
            margin-left: unset;
        }
    }
} 