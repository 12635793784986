@import '../../includes/settings.scss';

.contentBlock {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;

    .header {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 0 $spacingBlock 0;
        text-transform: uppercase;
    }

    .title-container {
        flex: 1 1 calc(100% - 120px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 calc(((100% - #{$maxWidth}) / 2) + 3rem) $spacingParagraph 0;

        .title {
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            background-color: $navbar-background;
            color: $text-light;
            border-radius: 0px 10px 10px 0px;
            padding: 1rem 2rem 1rem calc(((100vw - #{$navbar-width}) - #{$maxContentWidth})/2);
            text-transform: uppercase;
        }
    }

    .img-container {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 0 $spacingParagraph 0;

        .img {
            flex: 0 1 auto;
            max-width: $maxContentWidth;
        }
    }

    .vid-container {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 0 $spacingParagraph 0;

        .vid {
            flex: 0 1 auto;
            max-width: $maxContentWidth;
            overflow: hidden;
            position: relative;
            width: 100%;

            &::after {
                padding-top: 56.25%;    // Needed for 16:9 aspect ratio
                display: block;
                content: '';
            }

            iframe {
                width: 100%;
                height: auto;
            }
        }
    }

    .content-container {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        justify-content: center;
        
        .content {
            max-width: $maxContentWidth;

            p {
                margin: 0 0 1rem 0;
            }

            p:last-child {
                margin: 0 0 0 0;
            }
        }
    }
}

/* Large screens */
@media only screen and (min-width: 1921px) {
    .contentBlock {
        .title-container {
            padding: 0 calc((100% - #{$maxWidth})/2) $spacingParagraph calc((100% - #{$maxWidth})/2);
    
            .title {
                padding: 1rem 2rem;
                border-radius: 10px;
            }
        }
    
        .content-container {
            padding: 0 $mobileGutter;
        }
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .contentBlock {
        .header {
            text-align: center;
            padding: 0 $mobileGutter $spacingBlock $mobileGutter;
        }

        .title-container {
            padding: 0 $spacingParagraph $spacingParagraph 0;
    
            .title {
                .left, .right {
                    flex: 1 1 100%;
                    padding: 0 $mobileGutter 1rem $mobileGutter;
                }

                .right {
                    padding: 0 $mobileGutter 0 $mobileGutter;
                }
            }
        }
    
        .content-container {
            padding: 0 $mobileGutter;
        }
    }
}