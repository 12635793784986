@import '../../includes/settings.scss';

.footer {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ul {
        flex: 0 1 auto;
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: center;
        flex-wrap: wrap;

        li {
            flex: 0 1 auto;

            .footer-link.middle {
                padding: 0 3rem;
            }
        }
    }
}

/* Large screens */
@media only screen and (min-height: 1125px) {
    .footer.contact {
        position: fixed;
        bottom: $spacingParagraph;
        left: calc((100% - 250px) / 2);
    }
}