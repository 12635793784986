@import '../../includes/settings.scss';

.PPAPost-List {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .PPAPost {
        padding: 0 0 $spacingBlock 0;
        flex: 1 1 auto;
    }

    .PPAPost:last-child {
        padding: 0 0 0 0;
    }

    .load-more {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $spacingParagraph 0;
    }
}