@import '../../includes/settings.scss';

.loginButton-container {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 125px;
    padding: 10px 25px;
    background-color: $navbar-background;
    border-radius: 0px 0px 0px 10px;
    text-align: center;
    z-index: 10;

    .btn-login {
        flex: 1 1 100%;
        color: $text-light;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        font-family: 'Karla-Light';

        .large {
            font-size: 24px;
            line-height: 28px;

            /*
            &::after {
                content: '';
                width: 0;
                height: 2px;
                margin-top: .25rem;
                display: block;
                background-color: $text-light;
                transition: all .5s ease;
            }
            */
        }

        &:hover {
            .large {
                /*
                &::after {
                    width: 100%;
                }
                */
            }
        }
    }
}

/* Large screens */
@media only screen and (min-width: 1921px) {
    .loginButton-container {
        right: calc((100% - 1920px) / 2);
        border-radius: 0px 0px 10px 10px;
    }
}

/* Small screens */
@media only screen and (max-width: 1024px) {
    .loginButton-container {
        display: none;
    }
}